/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Img from "gatsby-image"

import "./layout.css"
import "./index.css"

const IndexLayout = ({innerStyle, children }) => {
    const data = useStaticQuery(graphql`query {
        selfyImage: file(relativePath: { eq: "selfy.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }`);

    return (<>
        <div style={{
            width: `100%`,
            minHeight: `100%`,
            position: `relative`
        }}>
            <div className="index-grid-parent">
                <div style={{
                    position: "relative"
                }}>
                    <Img fluid={data.selfyImage.childImageSharp.fluid} />
                    <div className="selfy-overlay"/>
                </div>
                <div className={innerStyle + ' index-main-wrapper'} style={{
                    position: "relative"
                }}>
                    <div className="main-container">{children}</div>
                    <nav className="bottom-nav">
                        <span>© {new Date().getFullYear()}, Yanase Takuma</span>
                        <ul className="index-nav">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about/">About</Link></li>
                            <li><Link to="/posts/">Posts</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </>);
}

IndexLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default IndexLayout
